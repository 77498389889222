import { SafeStyle } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ITrail as ITrailSignup } from '../../types';
import { IEvent } from './event.model';
import { ImageStyles } from './imageStyles.model';
import { Runner } from './runner.model';

interface HttpDictionary {
  [param: string]: string | number | boolean | undefined | readonly (string | number | boolean)[];
}

// User Roles
export enum DrupalRole {
  AnonymousUser = 1,
  AuthenticatedUser = 2,
  Administrator = 3,
  Runner = 4,
  EventOrganizer = 5,
  TemporaryRunner = 6,
  VerifiedTemporaryRunner = 7,
  Encoder = 8,
  LightUser = 9,
  VerifiedOrganizer = 10,
  Premium = 11,
  ForcedPremium = 12,
}

export enum RankingGenderValues {
  scratch = -1,
  female = 1,
}

export enum ERankingCode {
  LEVEL = 'level',
  TRAILCUP = 'tc_pts',
  ULTRACUP = 'uc_pts',
  SCORE_TRAIL = 'bts_score',
  SCORE_ULTRA = 'btu_score',
}

export enum ERankingName {
  LEVEL = 'level',
  TRAILCUP = 'trailcup',
  ULTRACUP = 'ultracup',
  SCORE_TRAIL = 'betrail-light',
  SCORE_ULTRA = 'betrail-ultra',
}

export interface IRankingParams {
  ranking: ERankingCode;
  country: string;
  type: string;
  gender: 'scratch' | 'female';
  mode: 'summary' | 'all_results';
}

export interface GameData {
  uid: number;
  ruid: number;
  type: string;
  node_type: string;
  nid: number;
  elements: number;
  points: number;
  created: number;
  updated: number;
}

export interface IOrgaTrail {
  alias: string;
  id: number;
  title: string;
  photo: {
    id: number;
    small_image: string;
    medium_image: string;
    large_image: string;
    square_image: string;
    little_square_image: string;
    max_100_height: string;
  };
  events: { alias: string; date: number }[];
}

export interface ISimulation {
  estimationType: string;
  estimatedTime: number;
  estimatedPosition: number;
  totalFinishers: number;
  rankPosition: number;
  estimatedPerformance: number;
  estimatedByBetrail: number;
  targetedRace: IRace;
  runnerHasRunThisRace: boolean;
  resultTime: number;
  finalResults: IResult[];
  regularity: number;
  trackProximity: number;
  fiability: number;
  usedRaces: IRace[];
  estimationDelta: number;
}

export interface IScore {
  id: string;
  title: string;
  bt_score: number;
  bt_min_perf: number;
  btu_score: number;
  btu_min_perf: number;
  bts_score: number;
  bts_min_perf: number;
  tc_points: number;
  tc_pts: number;
  uc_points: number;
  uc_pts: number;
  oldest_result_date: number;
  nb_races: number;
  nb_races_ultra: number;
  gender: number;
  year: number;
  bt_score_result_reid_1?: number;
  bt_score_result_reid_2?: number;
  bt_score_result_reid_3?: number;
  bts_score_result_reid_1?: number;
  bts_score_result_reid_2?: number;
  bts_score_result_reid_3?: number;
  btu_score_result_reid_1?: number;
  btu_score_result_reid_2?: number;
  country: string;
  created: number;
  ruid?: number;
  runner: IRunner;
  rank?: any;
}

export interface IScoreRank {
  bts_score?: number;
  btu_score?: number | null;
  contextualRank: number;
  country: string;
  gender?: 0 | 1;
  id: number;
  rank_bts_gender?: number;
  rank_bts_scratch?: number;
  rank_btu_gender?: number;
  rank_btu_scratch?: number;
  rank_tc_gender?: number;
  rank_tc_scratch?: number;
  rank_uc_gender?: number;
  rank_uc_scratch?: number;
  ruid_link_runner: number;
  runner?: IRunner;
  scid: string;
  scoreObject?: IScore;
  tc_pts?: number;
  total_bts_gender?: number;
  total_bts_scratch?: number;
  total_btu_gender?: number;
  total_btu_scratch?: number;
  total_tc_gender?: number;
  total_tc_scratch?: number;
  total_uc_gender?: number;
  total_uc_scratch?: number;
  uc_pts?: number;
  year: string;
}

export interface ILevel {
  id: string;
  level: number;
  last_result_date: number;
  level_result_reid_1?: number;
  level_result_reid_2?: number;
  level_result_reid_3?: number;
  leveltype: string;
  created?: number;
  ruid?: number;
  rank?: ILevelRank;
  ruid_link_to_rank?: number;
  ruid_link_to_runner?: number;
  runner?: IRunner;
}

export interface ILevelRank {
  contextualRank: number;
  country: string;
  gender: 0 | 1;
  hasResultOlderThanThreeYears?: boolean;
  id: number;
  level: number;
  levelObject?: ILevel;
  leveltype: string;
  nationality: string;
  rank_gender: number;
  rank_nat_gender: number;
  rank_nat_scratch: number;
  rank_scratch: number;
  ruid_link_level: number;
  ruid_link_runner: number;
  runner?: IRunner;
  total_gender: number;
  total_nat_gender: number;
  total_nat_scratch: number;
  total_scratch: number;
  year: string;
}

export interface ILevelProno {
  estimatedByBetrail: number;
  globalLengthSteepCoeff: number;
  type: {
    distance: number;
    elevation: number;
  };
}

export interface ITrailCupCountries {
  main: {
    country: string;
    nbResults: number;
    points: number;
  };
  other: {
    countries: string[];
    nbResults: number;
    points: number;
  };
}

export interface IResult {
  title: string;
  id: number;
  ruid: number;
  runner: IRunner;
  raid: number | string;
  race: IRace | null;
  uid: number;
  status: number;
  result_seconds: number;
  result_milliseconds: number;
  result_hms?: string;
  performance: number;
  points: number;
  pts: number;
  ultra: number;
  gender: number;
  date: number;
  country: string;
  category: string;
  notification_sent: string;
  original_infos: string;
  original_position: number;
  original_runner_name: string;
  original_runner_firstname: string;
  original_runner_lastname: string;
  original_gender: number;
  modifications: string;
  rank_scratch?: number;
  rank_gender?: number;
  finishers?: number;
  position?: number;
  femaleFinishers?: number;
  femalePosition?: number;
  created?: number;
  tag?: string | null;
  isOneOfBestScores?: boolean;
  nb_results?: number;
  eliteType?: 'world' | 'national';
}

export interface IResultCreation {
  ruid?: number;
  firstname: string;
  lastname: string;
  birthYear?: number;
  gender: 0 | 1;
  nationality?: string;
  raid: number;
  result_seconds: number;
}

export interface IOtherResult {
  id: number;
  uid: number;
  trid: number;
  evid: number;
  raid: number;
  ruid: number;
  race: IRace;
  runner?: IRunner;
  trail?: Trail;
  event?: IEvent;
  title: string;
  run_type: string;
  country: string;
  zipcode: number;
  location: string;
  ev_year: number;
  ev_date: string;
  distance: number;
  race_type: string;
  height_difference: number;
  night_race: boolean;
  result_type: number;
  result_seconds: number;
  result_milliseconds: number;
  position: number;
  total_finishers: number;
  distance_dnf: number;
  date: number;
  geo_lon: number;
  geo_lat: number;
  tag?: string | null;
  isOneOfBestScores?: boolean;
}

export interface IGameEvent {
  id: number;
  uid: number;
  ruid: number;
  runner?: IRunner;
  type: string;
  node_type: string;
  nid: number;
  race?: IRace;
  elements: number;
  points: number;
  created: number;
  updated: number;
}

export interface IClaimedElement {
  id: number;
  uid: number;
  ruid: number;
  runner?: Omit<Runner, 'user'>;
  type: string;
  element: string;
  element_id: number;
  active: number;
  days?: number;
  created_at: Date | string;
  updated_at?: Date | string;
}

export interface IEncodingEvent {
  alias: string;
  canceled: 0 | 1;
  claim: IClaimedElement | null;
  country: string;
  date: number;
  fullyEncoded: boolean;
  id: number;
  out_of_criteria: boolean;
  races: any[];
  title: string;
  trail: any;
}

export interface IRunnerVisualizerData {
  rtid?: number;
  ruid?: number;
  name?: string;
  alias?: string;
  nationality?: string;
  gender?: string;
  premium?: boolean;
  photo?: string;
  age?: number;
  place?: string;
  disabled: boolean;
}

export interface IRunnerTracking {
  id: number;
  uid: number;
  ruid: number;
  status: ETrackingStatus;
  override: 0 | 1;
  created_at: Date;
  updated_at?: Date;
}

export interface ITrackedRunner {
  ruid: number;
  created_at: Date;
  status: ETrackingStatus;
  runner: {
    alias: string;
    title: string;
    display_title: string | null;
    gender: 0 | 1;
    nationality: string;
    display_options: string | null;
    birthdate: number;
    place: string | null;
    is_premium: 0 | 1;
    photo: {
      little_square_image: string;
    } | null;
  } | null; // if runner = null -> runner has been deleted or fused with another
}

export interface ITrackingUser {
  id: number;
  override: 0 | 1;
  created_at: Date;
  user: {
    id: number;
    runner: {
      id: number;
      alias: string;
      title: string;
      display_title: string | null;
      gender: 0 | 1;
      nationality: string;
      display_options: string | null;
      birthdate: number;
      place: string | null;
      is_premium: 0 | 1;
      photo: {
        little_square_image: string;
      } | null;
    } | null; // if runner = null -> user is not runner or runner has been deleted or fused with another
  };
}

export enum ETrackingStatus {
  ACTIVE = 'active',
  PAUSED = 'paused',
  STOPPED = 'stopped',
}

export interface IEliteLevel {
  id: number;
  nationality: string;
  order: number;
  results: number;
  level_m: number;
  level_w: number;
  last_update: Date;
}

export interface INewsFeedGroup {
  id: number;
  name: string;
  priority: number;
  runners?: { ruid: number }[];
  display?: boolean;
}

export interface INewsFeedParams extends HttpDictionary {
  elid?: number | string;
  tgid?: number | string;
}

export interface ITrackingGroup {
  id: number;
  uid: number;
  name: string;
  priority: number;
  active: 0 | 1;
  created_at: Date;
  updated_at?: Date;
}

export interface ITrackingGroupRunner {
  tgid: number;
  ruid: number;
}

export type IRace = any;
export type IRunner = any;

export interface IItems {
  _id: string;
  type: ItemsType;
}

type ItemsType = 'REGISTRATION' | 'TEAM_REGISTRATION';

export interface IRegistration extends IItems {
  _id: string;
  uid: number;
  ruid: number;
  mail: string;
  createdAt: string;
  firstname: string;
  lastname: string;
  birthdate: string;
  country: string;
  gender: number;
  routeId: string;
  status: string;
  address: string;
  paymentStatus?: string;
  paymentMethod?: string;
  bib?: number;
  formData?: any;
  paymentInfo?: any;
  transferInfo?: any;
  extraFields?: any;
  orderId?: string;
  runners?: any[];
  amount?: number;
  race?: {
    date: number;
    id?: number | string;
  };
  routeInfo?: any;
  distanceChangesLogs?: any[];
}

export interface IRegion {
  code: string;
  title: string;
  url_alias: string;
  departments?: IDepartment[];
}

export interface IDepartment {
  code: string;
  title: string;
  url_alias: string;
}

export class Organization {
  id: number;
  name: string;
  email: string;
  legal_form: string;
  phone: string;
  mango_user_id: string;
  web_site: string;
  facebook_page: string;
  description: string;
  trails?: Trail[];
  users?: { mail: string; name: string; id: number }[];
}

export class UserOrganization {
  user_id: number;
  organization_id: number;
}

export class INotif {
  id?: number;
  notif_id?: number;
  type: string;
  uid?: number;
  model: string;
  created: number;
  createdDate?: Date;
  status?: string;
  image?: string;
  event_name?: string;
  alias?: string;
  reid?: number;
  date?: number;
  url?: string;
  target_id?: number;
}

export interface Trail {
  id: number;
  title: string;
  uid: number;
  status?: number;
  website?: string;
  facebook?: string;
  postal_code?: string;
  place?: string;
  country?: string;
  organizer?: string;
  organizer_email?: string;
  organizer_phone?: string;
  gpx_option: number;
  organization: Organization;
  closed?: number;
  geo_lat?: number;
  geo_lon?: number;
  man_lat?: number;
  man_lon?: number;
  alias?: string;
  alias2?: string;
  photo?: ImageStyles;
  events?: any[];
  eventIds?: number[];
  image?: any;
  hide?: number;
  coverUrl?: Observable<SafeStyle>;
  signupData?: ITrailSignup;
  permissions?: any;
  nearEvents?: IEvent[];
  near_countries?: string;
  other_used_countries?: string;
}

export interface ITimer {
  id: number | string;
  uid: number;
  alias: string;
  status: number;
  title: string;
  country: string;
  email: string;
  phone: string;
  place: string;
  postal_code: string;
  website: string;
  default_results_page: string;
  dont_exist: number;
  authorization: number;
  logo?: number;
  photo?: any;
  nb_events?: number;
  nb_results?: number;
  events?: IEvent[];
  geo_lat?: number;
  geo_lon?: number;
  created?: number;
  changed?: number;
}

export enum RaceCategory {
  REFUSED = 0,
  OTHERS = 1,
  NATURE_XL = 2,
  START_TO_TRAIL = 3,
  TRAIL = 4,
  LABEL = 5,
  // ??? 6 ???
  NATURE = 7,
}

export enum SubscriptionStatus {
  NEW = 0,
  ENABLED = 1,
  COMPLETED = 2, // = NO MORE VALID
  DELETED = 3,
  WAITING_PAYMENT = 4,
}

export enum PaymentSubscriptionStatus {
  NOT_PAYED = 0,
  PAYED = 1,
  NOT_SUCCEEDED_1 = 2,
  NOT_SUCCEEDED_2 = 3,
}

export interface IPremiumReportMonth {
  period: string;
  sortString: string;
  subs: number;
  income: number;
  tva: number;
  h_tva: number;
  mango_fee: number;
  subscriptions: {
    duration: 'month' | 'year';
    h_tva: number;
    income: number;
    mango_fee: number;
    subs: number;
    tva: number;
    type: string;
  }[];
}

export interface IPremiumReport {
  by_month: IPremiumReportMonth[];
  subs: number;
  income: number;
  tva: number;
  h_tva: number;
  mango_fee: number;
}

export interface IPremiumSubscription {
  id: number;
  user_id: number;
  prid: number;
  ref_price?: IPremiumRefPrice;
  start_date: Date;
  end_date: Date;
  cancel_date?: Date;
  premium_type: string;
  subscription_type: string;
  subscription_date: Date;
  projected_payment_date: Date;
  amount_paid?: number;
  status: SubscriptionStatus;
  payment_status: PaymentSubscriptionStatus;
  auto_renewal: number;
  coid?: number;
  included_trial_period?: number;
  permissions?: any;
  validation_timeout: Date;
}

export interface IPremiumRefPrice {
  id: number;
  start_date: Date;
  end_date: Date;
  premium_type: string;
  subscription_type: string;
  duration_months: number;
  additional_trial_period: number;
  price_type: string;
  price_comment: string;
  price_amount: number;
  price_name: string;
  striked_price_amount: number;
  renewal_prid: number;
}

export interface IMangoUser {
  uid: number;
  mango_user_id: string;
  mango_data?: string;
  created?;
  updated?;
  active: boolean;
  mango_sandbox: boolean; // 1 if sandbox, 0 if not (sandbox is test environment of mangopay)
}

export interface IEncodageRace {
  id?: number;
  eventId: number;
  currentStep: string;
  lastData?: any;
}

export interface IAdminSettings {
  type: EAdminSettingType;
  data: string;
  updatedAt: Date;
  createdAt: Date;
}

export enum EAdminSettingType {
  DISCOVER_LINK = 'discover-link',
  AD_MY_SPORTS_BANNER = 'ad-my-sports-banner',
}

export interface IFormItem {
  order: number;
  name: string;
  key: string;
  draggable?: boolean;
  //type: string;
  fieldConfig: IFormFieldConfig;
  status: string;
  mode?: string;
}

export interface IFormSection {
  id: number;
  draggable: boolean;
  order: number;
  formItems: IFormItem[];
  title: string;
  showTitle: boolean;
  canBeRemoved: boolean;
  allowAddItem: boolean;
}

export interface IFormFieldConfig {
  type: string;
  required: boolean;
  options?: any[];
  isSystem: boolean;
}

export interface IUserGameEvents {
  user: {
    title: string;
    account_created: number;
    alias: string;
    firstname?: string;
    lastname?: string;
    nationality?: string;
    ruid?: number;
    uid: number;
    user?: { roles: { rid: number }[] };
  };
  events: { [type: string]: IGameEvent[] };
}

export interface IGameGridData {
  uid: number;
  ruid?: number;
  name: string;
  alias: string;
  nationality?: string;
  member_since: number;
  is_encoder: boolean;
  premium_type: 'none' | 'forced' | 'user';
  points_total: number;
  points_calendar: number;
  points_encoding: number;
  points_race: number;
  points_mail: number;
}

export interface ISearchRaceResult {
  evid: number | string;
  event_alias: string;
  hide: 0 | 1;
  score: number;
  trail_alias: string;
  trail_title_original: string;
  date_milliseconds: number;
  country?: string;
  image_small_url?: string;
  place?: string;
  postal_code?: string;
  canceled: boolean;
  out_of_criteria: boolean;
  added_coeff: number;
  close_match: boolean;
}

export interface ISearchRunnerResult {
  score: number;
  country: string | null;
  gender: 0 | 1;
  has_account: boolean;
  is_premium: boolean;
  nationality: string | null;
  place?: string;
  postal_code?: string;
  display_place?: true;
  display_age?: true;
  age?: number;
  alias: string;
  display_title: string;
  ruid: number | string;
  uid?: number | string;
  avatar?: string;
  bt_level: number;
  nb_results: number;
  added_coeff: number;
  close_match: boolean;
}
